export { isVue2, isVue3 } from '#app/app';
export { setNuxtAppInstance, useNuxtApp, defineNuxtPlugin, useRuntimeConfig, useNuxt2Meta } from '#app/nuxt';
export { defineNuxtComponent } from '#app/composables/component';
export { useRoute, useRouter, abortNavigation, addRouteMiddleware, defineNuxtRouteMiddleware, navigateTo } from '#app/composables/router';
export { useState } from '#app/composables/state';
export { useLazyAsyncData, refreshNuxtData } from '#app/composables/asyncData';
export { clearError, createError, isNuxtError, showError, useError, throwError } from '#app/composables/error';
export { useLazyFetch } from '#app/composables/fetch';
export { useCookie } from '#app/composables/cookie';
export { useRequestHeaders, useRequestEvent } from '#app/composables/ssr';
export { useAsyncData, useFetch, useHydration } from '#app/mocks';
export { onBeforeRouteLeave, onBeforeRouteUpdate, useLink } from 'vue-router/composables';
export { onActivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate, onDeactivated, onErrorCaptured, onMounted, onRenderTracked, onRenderTriggered, onServerPrefetch, onUnmounted, onUpdated, computed, customRef, isProxy, isReactive, isReadonly, isRef, markRaw, proxyRefs, reactive, readonly, ref, shallowReactive, shallowReadonly, shallowRef, toRaw, toRef, toRefs, triggerRef, unref, watch, watchEffect, isShallow, effectScope, getCurrentScope, onScopeDispose, defineComponent, defineAsyncComponent, getCurrentInstance, h, inject, nextTick, provide, useAttrs, useCssModule, useCssVars, useSlots } from 'vue';
export { useBalanceKeyFigures, useAllTenanciesQuery, useFinancialQuery, usePropertyTenancyTypesQuery, usePortfolioTenancyTypesQuery, useAccountSpecificationsQuery, usePropertyNamesQuery, usePortfolioRentRegulationPrinciplesQuery, useLazyCategoryChildrenQuery, useAggregationCategoriesQuery, useBudgetsPropertyQuery, useUsersQuery } from '/app/composables/queries/index';
export { useAgentsQuery } from '/app/composables/useAgentsQuery';
export { useAmplitude } from '/app/composables/useAmplitude';
export { useAuth0 } from '/app/composables/useAuth0';
export { useBalanceCellCopyColor } from '/app/composables/useBalanceCellCopyColor';
export { useBalanceCellCopyText } from '/app/composables/useBalanceCellCopyText';
export { useBalanceCurrentPeriod } from '/app/composables/useBalanceCurrentPeriod';
export { useBalanceRowValues } from '/app/composables/useBalanceRowValues';
export { useBalanceSelectedDateRange } from '/app/composables/useBalanceSelectedDateRange';
export { useCustomUrlParam, useCustomUrlParams } from '/app/composables/useCustomUrlParams';
export { useDataTableRows } from '/app/composables/useDataTableRows';
export { useDebounce } from '/app/composables/useDebounce';
export { useDownloadPaginatedQuery } from '/app/composables/useDownloadPaginatedQuery';
export { useDownloadableChart } from '/app/composables/useDownloadableChart';
export { filter, useFilter } from '/app/composables/useFilter';
export { useInject } from '/app/composables/useInject';
export { useForm } from '/app/composables/useForm';
export { useIsFeatureSupported } from '/app/composables/useIsFeatureSupported';
export { useIsMobile } from '/app/composables/useIsMobile';
export { useMe } from '/app/composables/useMe';
export { useOpexSelectedDateRange } from '/app/composables/useOpexSelectedDateRange';
export { PERMISSIONS, useHasPermissions } from '/app/composables/usePermissions';
export { usePreferences } from '/app/composables/usePreferences';
export { usePreferencesNamespace } from '/app/composables/usePreferencesNamespace';
export { useQueryFilters } from '/app/composables/useQueryFilters';
export { useReactiveClone } from '/app/composables/useReactiveClone';
export { useTenanciesQueryVariables } from '/app/composables/useTenanciesQueryVariables';
export { useTenanciesTableHeaders } from '/app/composables/useTenanciesTableHeaders';
export { useTenanciesUrlParams, useTenanciesUrlParamRefs, useTenanciesPortfolioIds, useTenanciesPropertyIds, useTenanciesUrlParamsWithSavedFilter } from '/app/composables/useTenanciesUrlParams';
export { useTranslateCurrency } from '/app/composables/useTranslateCurrency';
export { useTranslateUnit } from '/app/composables/useTranslateUnit';
export { useTranslator, useCountTranslator } from '/app/composables/useTranslator';
export { setUrlParamValue, freezeUrlParams, unfreezeUrlParams, useUrlParam, useUrlParamNumber, useUrlParamBoolean, useUrlParamString, useUrlParamDate, useUrlParamStringArray, useUrlParamSort, useUrlParams } from '/app/composables/useUrlParam';
export { useUrlTabsPortfolio } from '/app/composables/useUrlTabsPortfolio';
export { useUrlTabsProperty } from '/app/composables/useUrlTabsProperty';
export { useUrlTabsTenancy } from '/app/composables/useUrlTabsTenancy';
export { useWindowSize } from '/app/composables/useWindowSize';